import React from 'react'

function SettingsIcon({ fill = 'white', height = 24, width = 24 }) {
  return (
    <svg
      width={width || '100%'}
      height={height}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='Filled/settings'>
        <path
          id='Icon'
          fillRule='evenodd'
          clipRule='evenodd'
          d='M12 15.5C10.07 15.5 8.5 13.93 8.5 12C8.5 10.07 10.07 8.5 12 8.5C13.93 8.5 15.5 10.07 15.5 12C15.5 13.93 13.93 15.5 12 15.5ZM20.317 9.375H19.233C19.086 9.375 18.986 9.258 18.925 9.111C18.879 9 18.867 8.846 18.972 8.741L19.742 7.975C20.06 7.658 20.236 7.235 20.236 6.787C20.236 6.337 20.062 5.915 19.744 5.598L18.403 4.257C17.749 3.601 16.682 3.603 16.026 4.257L15.256 5.028C15.152 5.133 14.998 5.12 14.853 5.06C14.743 5.015 14.625 4.914 14.625 4.767V3.676C14.625 2.752 13.874 2 12.949 2H11.057C10.129 2 9.375 2.755 9.375 3.683V4.767C9.375 4.914 9.258 5.014 9.111 5.075C9 5.122 8.845 5.134 8.741 5.028L7.976 4.259C7.658 3.94 7.236 3.765 6.787 3.765H6.785C6.336 3.765 5.914 3.939 5.597 4.257L4.257 5.597C3.602 6.251 3.602 7.317 4.257 7.974L5.028 8.744C5.132 8.848 5.12 9.003 5.06 9.147C5.015 9.257 4.914 9.375 4.767 9.375H3.676C2.752 9.375 2 10.126 2 11.051V12.943C2 13.871 2.755 14.625 3.683 14.625H4.767C4.914 14.625 5.014 14.742 5.075 14.889C5.121 15 5.133 15.154 5.028 15.259L4.259 16.024C3.94 16.342 3.765 16.764 3.765 17.213C3.764 17.663 3.939 18.085 4.257 18.403L5.597 19.743C6.251 20.399 7.318 20.397 7.974 19.743L8.744 18.972C8.849 18.869 9.003 18.881 9.147 18.94C9.257 18.985 9.375 19.086 9.375 19.233V20.324C9.375 21.248 10.126 22 11.051 22H12.943C13.871 22 14.625 21.245 14.625 20.317V19.233C14.625 19.086 14.742 18.986 14.889 18.925C14.999 18.879 15.154 18.867 15.259 18.972L16.024 19.741C16.342 20.06 16.764 20.235 17.213 20.235H17.215C17.664 20.235 18.086 20.061 18.403 19.743L19.743 18.403C20.398 17.749 20.398 16.683 19.743 16.026L18.972 15.256C18.868 15.152 18.88 14.997 18.927 14.886C18.927 14.884 18.94 14.854 18.94 14.853C18.985 14.743 19.086 14.625 19.233 14.625H20.324C21.248 14.625 22 13.874 22 12.949V11.057C22 10.129 21.245 9.375 20.317 9.375Z'
          fill={fill}
        />
      </g>
    </svg>
  )
}

export default SettingsIcon
