/**
 * Created by kyle-ssg on 10/02/15.
 */
module.exports = {
  'LOGIN': 'LOGIN',
  'LOGOUT': 'LOGOUT',
  'REFRESH': 'REFRESH',
  'REGISTER': 'REGISTER',
  'REGISTER_NOTIFICATIONS': 'REGISTER_NOTIFICATIONS',
  'RESET_PASSWORD': 'RESET_PASSWORD',
  'SET_TOKEN': 'SET_TOKEN',
  'SET_USER': 'SET_USER',
}
